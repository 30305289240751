import React, { Component } from "react";
import NavBar from "../NavBar";
import { Link } from "react-router-dom";

class Blog extends Component {
  render() {
    return (
      <div>
        <h1>Blog</h1>
        <div>
          <p className="blu">
            Want to know how that cool coding trick really works? <br />
            And how to invent the next one yourself? <br />
            Why not try{" "}
            <Link to={"/software-spells"}>
              <u>Software Spells</u>
            </Link>{" "}
            now?
          </p>
        </div>
        <NavBar />
      </div>
    );
  }
}

export default Blog;
