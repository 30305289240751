import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./Sections/Home";
import Projects from "./Sections/Projects";
import Blog from "./Sections/Blog";
import Contact from "./Sections/Contact";
import Scroller from "./SoftwareSpells/Scroller";
import "./App.css";

ReactGA.initialize("UA-164770161-1", {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  componentDidMount() {
    document.title = "Ivan";
  }

  render() {
    return (
      <div>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route path={"/links/dream2real"} exact render={() => {
                window.location.replace(`${process.env.PUBLIC_URL}/papers/dream2real.pdf`);
                return null;
            }} />
            <Route path={"/links/scenescore"} exact render={() => {
                window.location.replace(`${process.env.PUBLIC_URL}/papers/scenescore.pdf`);
                return null;
            }} />
            <Route path={"/"} exact>
                <Home />
            </Route>
            <Redirect to={"/"} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
