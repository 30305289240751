import React, { Component } from "react";
import NavBar from "../NavBar";

class Contact extends Component {
  render() {
    return (
      <div>
        <h1>Contact</h1>
        <div>
          <p className="blu">Please feel free to get in touch:</p>

          <a
            href="https://www.linkedin.com/in/ivan-kapelyukh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-linkedin link-icon"></span>
          </a>

          <a
            href="https://www.facebook.com/ivan.kapelyukh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-facebook link-icon"></span>
          </a>
        </div>
        <NavBar />
      </div>
    );
  }
}

export default Contact;
