import React, { Component } from "react";
import "./SoftwareSpells.css";

class Scroller extends Component {
  render() {
    return (
      <div>
        <h1>Software Spells</h1>
        <div>
          <p className="subtitle">Useful magic. Explained simply.</p>
        </div>
      </div>
    );
  }
}

export default Scroller;
