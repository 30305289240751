import React, { Component } from "react";
import profile from '../profile.jpg';

class Home extends Component {
  render() {
    return (
      <div id='intro-block'>
        <h1>Welcome!</h1>
        <div>
          <img id='profile-pic' src={profile} align='right'></img>

          <p>
            My name is <b className='blu'>Ivan Kapelyukh</b>. Thank you for visiting my website.
          </p>

          <p>
            I am a PhD student at <b className='blu'>Imperial College London</b>, working on robot learning and computer vision. I am interested in creating intelligent robots which can perform everyday household tasks with minimal human supervision. I have previously worked with object rearrangement, NeRF, diffusion models, segmentation methods, energy-based models, graph neural networks, visual-language models, and object pose estimation methods.
          </p>

          <p>
            My PhD is jointly supervised by <b className='blu'>Dr Edward Johns</b> (at the <a target='_blank' href='https://www.robot-learning.uk/'>Robot Learning Lab</a>) and <b className='blu'>Prof Andrew Davison</b> (at the <a target='_blank' href='https://www.imperial.ac.uk/dyson-robotics-lab'>Dyson Robotics Lab</a>).
          </p>

          <p>
            If you would like to get in touch, please email: <b className='blu'>ik517@ic.ac.uk</b>
          </p>
        </div>
        <a
            href="https://scholar.google.com/citations?user=DkNQTkoAAAAJ"
            target="_blank"
            rel="noopener noreferrer"
        >
          <span className="fas fa-graduation-cap link-icon"/>
        </a>
        <a
            href="https://github.com/ivan-kapelyukh"
            target="_blank"
            rel="noopener noreferrer"
        >
          <span className="fab fa-github link-icon"/>
        </a>
        <a
            href="https://twitter.com/IvanKapelyukh"
            target="_blank"
            rel="noopener noreferrer"
        >
          <span className="fab fa-twitter link-icon"/>
        </a>
        <a
            href="https://www.linkedin.com/in/ivan-kapelyukh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-linkedin-in link-icon"></span>
          </a>
      </div>
    );
  }
}

export default Home;
