import React, { Component } from "react";
import NavBar from "../NavBar";

class Projects extends Component {
  render() {
    return (
      <div>
        <h1>Projects</h1>
        <div>
          <p className="blu">
            Try out{" "}
            <a href={"https://ivankapelyukh.com/big-o-meter"}>
              <u>Big-O-Meter</u>
            </a>{" "}
            to see how well your code scales. <br />
            More programming projects here:
          </p>

          <a
            href="https://www.github.com/ivan-kapelyukh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-github-square link-icon"></span>
          </a>

          <a href="https://devpost.com/ivankapelyukh17" target="_blank" rel="noopener noreferrer">
            <span className="fab fa-dev link-icon"></span>
          </a>

          <a
            href="https://www.hackerrank.com/ivan_kapelyukh17"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-hackerrank link-icon"></span>
          </a>
        </div>
        <NavBar />
      </div>
    );
  }
}

export default Projects;
